.btn {
  $this: &;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  transition: all .3s;
  color: $white;
  border-radius: 4px;
  padding: 4px 24px;
  text-align: center;
  &#{$this}--blue {
    background-color:#3e6ae1;
    &:hover {
      background-color: #2560fe;
    }
  }
  &#{$this}--border {
    color: $black;
    box-shadow: inset 0 0 0 3px #3e6ae1;
    &:hover {
      color: $white;
      background-color: #3e6ae1;
    }
  }
}