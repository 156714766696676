
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-ExtraLight.woff2') format('woff2');
  src: url('../fonts/Montserrat/Montserrat-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2');
  src: url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Medium.woff2') format('woff2');
  src: url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2');
  src: url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2');
  src: url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Gilroy

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy-Light.woff2') format('woff2');
  src: url('../fonts/Gilroy/Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy-LightItalic.woff2') format('woff2');
  src: url('../fonts/Gilroy/Gilroy-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy-UltraLight.woff2') format('woff2');
  src: url('../fonts/Gilroy/Gilroy-UltraLight.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy-Regular.woff2') format('woff2');
  src: url('../fonts/Gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy-Medium.woff2') format('woff2');
  src: url('../fonts/Gilroy/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy-SemiBold.woff2') format('woff2');
  src: url('../fonts/Gilroy/Gilroy-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy-Bold.woff2') format('woff2');
  src: url('../fonts/Gilroy/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Tesla

@font-face {
  font-family: 'Tesla';
  src: url('../fonts/Tesla/tesla-seredich.woff2') format('woff2');
  src: url('../fonts/Tesla/tesla-seredich.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Travels

@font-face {
  font-family: 'Travels';
  src: url('../fonts/Travels/TTTravels-Regular.woff2') format('woff2');
  src: url('../fonts/Travels/TTTravels-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Travels';
  src: url('../fonts/Travels/TTTravels-Medium.woff2') format('woff2');
  src: url('../fonts/Travels/TTTravels-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Travels';
  src: url('../fonts/Travels/TTTravels-DemiBold.woff2') format('woff2');
  src: url('../fonts/Travels/TTTravels-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Travels';
  src: url('../fonts/Travels/TTTravels-Bold.woff2') format('woff2');
  src: url('../fonts/Travels/TTTravels-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham/GothamSSm-Medium.woff2') format('woff2');
  src: url('../fonts/Gotham/GothamSSm-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GothamPro';
  src: url('../fonts/GothamPro/GothamPro.woff2') format('woff2');
  src: url('../fonts/GothamPro/GothamPro.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GothamPro';
  src: url('../fonts/GothamPro/GothamPro-Medium.woff2') format('woff2');
  src: url('../fonts/GothamPro/GothamPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GothamPro';
  src: url('../fonts/GothamPro/GothamPro-Bold.woff2') format('woff2');
  src: url('../fonts/GothamPro/GothamPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copyright';
  src: url('../fonts/Copyright/Copyright.woff2') format('woff2');
  src: url('../fonts/Copyright/Copyright.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}