footer { flex: 0 0 auto; }

.footerHome {
  $this: &;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ededed;
  @include media(461px) {
   justify-content: flex-start;
  }
  &__top {
    width: 100%;
    max-width: 1400px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 100px 0 50px;
    @include media(1100px) {
      flex-wrap: wrap;
      padding: 20px 0;
    }
    @include media(461px) {
      margin: 10% 0;
    }
    @include media(376px) {
      margin: 10% auto auto;
    }
    &-img {
      @include media(1100px) {
        width: 100%;
        display: flex;
        justify-content: center;
        order: -1;
        text-align: center;
        img { max-width: 310px; flex: 0 0 auto; }
      }
      @include media(769px) {
        img { max-width: 270px; }
      }
      @include media(461px) {
        img { max-width: 180px; height: 230px;}
      }
      @include media(376px) {
        img { max-width: 160px; height: 207px;}
      }
    }
    &-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      font-weight: 500;
      font-size: 15px;
      gap: 10px;
      padding: 12.5px 30px;
      color: rgba(0, 0, 0, 0.6);
      background-color: $white;
      border-radius: 5px;
      @include media(769px) {
        padding: 12.5px 15px;
      }
      @include media(461px) {
        padding: 10px 15px;
        font-size: 14px;
      }
      img {
        @include size(30px);
        flex: 0 0 auto;
        @include media(461px) {
          @include size(20px);
        }
      }
      &:hover {
        background-color: $tds-color--black;
        color: $white;
      }
    }
    &-left {
      align-self: flex-start;
      text-align: right;
      #{$this}__top-title:after {
        left: auto;
        right: 0;
        @include media(461px) {
          left: 50%;
        }
      }
      #{$this}__top-text {
        margin-left: auto;
      }
    }
    &-item {
      position: relative;
      flex: 0 0 380px;
      padding: 30px 0;
      @include media(1100px) {
        padding: 10px 0;
        flex: 0 0 calc(50% - 40px);
      }
      @include media(769px) {
        flex: 0 0 calc(50% - 20px);
      }
      @include media(461px) {
        text-align: center;
        flex: 0 0 100%;
      }
    }
    &-text {
      max-width: 280px;
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 25px;
      @include media(461px) {
        font-size: 14px;
        line-height: 19px;
        margin: 0 auto 10px;
      }
    }
    &-title {
      position: relative;
      font-weight: 600;
      font-size: 48px;
      line-height: 59px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      @include media(769px) {
        font-size: 30px;
        line-height: 42px;
      }
      @include media(461px) {
        font-size: 24px;
        line-height: 30px;
      }
      &:after {
        content: "";
        width: 170px;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        @include media(461px) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &-after {
      position: absolute;
      background: linear-gradient( 90.06deg, #000000 9.53%, rgba(0, 0, 0, 0.37) 63.02% );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 0.05;
      font-weight: 600;
      font-size: 200px;
      line-height: 244px;
      @include media(1200px) {
        display: none;
      }
    }
    &-right {
      align-self: flex-end;
      @include media(1025px) {
        align-self: flex-start;
      }
      #{$this}__top-after {
        bottom: 90%;
        left: -200px;
      }
    }
  }
  &__bottom {
    background-color: $tds-color--black;
    padding: 50px 15px;
    @include media(1100px) {
      padding: 25px 15px;
      margin-bottom: 70px;
    }
    @include media(461px) {
      display: none;
    }
    &-inner {
      width: 100%;
      max-width: 1400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 auto;
      color: $white;
      @include media(1100px) {
        gap: 20px;
      }
    }
    &-socials {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      font-weight: 500;
      font-size: 15px;
      a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: $white;
      }
      img {
        @include size(30px);
        flex: 0 0 auto;
      }
    }
    &-phone {
      display: flex;
      flex-direction: column;
      gap: 10px;
      a {
        color: $white;
        font-size: 20px;
      }
    }
    &-address {
      font-size: 15px;
      color: rgba(255, 255, 255, 0.5);
    }
    &-left {
      font-weight: 600;
      font-size: 24px;
      @include media(1100px) {
        display: none;
      }
    }
  }
}