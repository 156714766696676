.modal-phone {
  $this: &;

  position: absolute;
  z-index: 10;
  width: calc(100% + 25px);
  display: flex;
  justify-content: space-between;
  left: 0;
  top: calc(100% + 10px);
  transform: translateY(20px);
  transition: transform 0.3s !important;
  padding: 0 12px;
  flex-direction: column;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  border-radius: 4px;
  &:after {
    content: '';
    @include size(100%);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    border-radius: 4px;
    backdrop-filter: blur(16px);
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:before {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    height: 10px;
  }
  &__wrapper {
    &:not(:last-child) {
      border-bottom: 1px solid $black;
    }
    &:first-child {
      #{$this}__item {
        border-radius: 4px 4px 0 0;
      }
    }
    &:last-child {
      #{$this}__item {
        border-radius: 0 0 4px 4px;
      }
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: #393c41;
    font-size: 12px;
    margin: 0 -12px;
    transition: all 0.3s;
    a {
      color: #393c41;
    }
    &:hover {
      color: #3a3a3a;
      background-color: #d4d4d4;
    }
  }
  &__icon {
    @include size(18px);
    flex: 0 0 auto;
    transition: fill 0.3s;
  }
}

.modalMenu {
  width: 310px;
  position: fixed;
  height: 100%;
  z-index: 10000;
  transform: translateX(100%);
  right: 0;
  top: 0;
  overflow: auto;
  font-family: 'Gotham', sans-serif;
  padding: 100px 32px 48px 20px;
  transition: all .3s ease-in-out;
  background-color: $white;
  @include media(1000px) {
    padding-top: 48px;
  }
  @include media(360px) {
    width: 100%;
  }
  &.open {
    transform: translateX(0);
  }
  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    &-icon {
      @include size(30px);
      @include media(414px) {
        @include size(24px);
      }
    }
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &.hidden {
      display: none;
      @include media(1000px) {
        display: block;
      }
    }
  }
  &__link {
    display: block;
    font-size: 14px;
    color: $black;
    transition: all .3s;
    padding: 12px 8px 12px 24px;
    &:hover {
      border-radius: 4px;
      backdrop-filter: blur(16px);
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}