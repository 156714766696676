// Colors
$white: #ffffff;
$black: #000000;
$tds-bezier: cubic-bezier(0.5,0,0,0.75);
$tds-size: 8px;
$tds-size--10x: 80px;
$tds-size--11x: 88px;
$tds-size--12x: 96px;
$tds-size--13x: 104px;
$tds-size--half: 4px;
$tds-size--1x: 8px;
$tds-size--2x: 16px;
$tds-size--3x: 24px;
$tds-size--4x: 32px;
$tds-size--5x: 40px;
$tds-size--6x: 48px;
$tds-size--7x: 56px;
$tds-size--8x: 64px;
$tds-size--9x: 72px;
$tds-color--black: #252525;
$tds-color--blue10: #2e4994;
$tds-color--blue20: #3457b1;
$tds-color--blue30: #3e6ae1;
$tds-color--green: #12bb00;
$tds-color--grey10: #171a20;
$tds-color--grey15: #222;
$tds-color--grey20: #393c41;
$tds-color--grey25: #444;
$tds-color--grey30: #5c5e62;
$tds-color--grey35: #8e8e8e;
$tds-color--grey40: #a2a3a5;
$tds-color--grey45: #bbb;
$tds-color--grey50: #d0d1d2;
$tds-color--grey60: #e2e3e3;
$tds-color--grey65: #eee;
$tds-color--grey70: #f4f4f4;
$tds-color--red10:  #b74134;
$tds-color--red20:  #ed4e3b;
$tds-color--yellow: #fbb01b;
$tds-toggle--background-color: hsla(0,0%,58.8%,0.1);



// Sizes

$base-font-size: 16px;

$breakpoints: (
  "phone"       : 400px,
  "phone-wide"  : 480px,
  "phablet"     : 560px,
  "tablet-small": 640px,
  "tablet"      : 768px,
  "tablet-wide" : 1024px,
  "desktop"     : 1248px,
  "desktop-wide": 1440px
);

$content-size: 1024px;

// Fonts

$base-fonts: 'Montserrat', sans-serif;