@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-ExtraLight.woff2") format("woff2");
  src: url("../fonts/Montserrat/Montserrat-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-Regular.woff2") format("woff2");
  src: url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-Medium.woff2") format("woff2");
  src: url("../fonts/Montserrat/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2");
  src: url("../fonts/Montserrat/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-Bold.woff2") format("woff2");
  src: url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy/Gilroy-Light.woff2") format("woff2");
  src: url("../fonts/Gilroy/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy/Gilroy-LightItalic.woff2") format("woff2");
  src: url("../fonts/Gilroy/Gilroy-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy/Gilroy-UltraLight.woff2") format("woff2");
  src: url("../fonts/Gilroy/Gilroy-UltraLight.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy/Gilroy-Regular.woff2") format("woff2");
  src: url("../fonts/Gilroy/Gilroy-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy/Gilroy-Medium.woff2") format("woff2");
  src: url("../fonts/Gilroy/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy/Gilroy-SemiBold.woff2") format("woff2");
  src: url("../fonts/Gilroy/Gilroy-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy';
  src: url("../fonts/Gilroy/Gilroy-Bold.woff2") format("woff2");
  src: url("../fonts/Gilroy/Gilroy-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Tesla';
  src: url("../fonts/Tesla/tesla-seredich.woff2") format("woff2");
  src: url("../fonts/Tesla/tesla-seredich.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Travels';
  src: url("../fonts/Travels/TTTravels-Regular.woff2") format("woff2");
  src: url("../fonts/Travels/TTTravels-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Travels';
  src: url("../fonts/Travels/TTTravels-Medium.woff2") format("woff2");
  src: url("../fonts/Travels/TTTravels-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Travels';
  src: url("../fonts/Travels/TTTravels-DemiBold.woff2") format("woff2");
  src: url("../fonts/Travels/TTTravels-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Travels';
  src: url("../fonts/Travels/TTTravels-Bold.woff2") format("woff2");
  src: url("../fonts/Travels/TTTravels-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham/GothamSSm-Medium.woff2") format("woff2");
  src: url("../fonts/Gotham/GothamSSm-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'GothamPro';
  src: url("../fonts/GothamPro/GothamPro.woff2") format("woff2");
  src: url("../fonts/GothamPro/GothamPro.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'GothamPro';
  src: url("../fonts/GothamPro/GothamPro-Medium.woff2") format("woff2");
  src: url("../fonts/GothamPro/GothamPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'GothamPro';
  src: url("../fonts/GothamPro/GothamPro-Bold.woff2") format("woff2");
  src: url("../fonts/GothamPro/GothamPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Copyright';
  src: url("../fonts/Copyright/Copyright.woff2") format("woff2");
  src: url("../fonts/Copyright/Copyright.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

html {
  font-size: 16px;
  height: 100%; }

body {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0; }
  body.hidden {
    overflow: hidden; }
  @media screen and (max-width: 1100px) {
    body {
      overflow: unset; } }

.pg-wrapper.pg-vertical {
  overflow: hidden; }

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

a {
  text-decoration: none; }

p {
  margin: 0; }

img {
  max-width: 100%;
  display: block; }

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
  vertical-align: middle; }

textarea {
  resize: none; }

section {
  position: relative; }

input,
select,
button {
  outline: none;
  cursor: pointer;
  border: none;
  background-color: transparent; }

*, *:before, *:after {
  box-sizing: border-box; }

.content {
  flex: 1 0 auto; }

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px; }

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  left: 50%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: background-color 0.5s ease, backdrop-filter 0.5s ease;
  z-index: 999; }
  .overlay.open {
    backdrop-filter: blur(4px);
    pointer-events: inherit;
    opacity: 1;
    visibility: visible; }

html.fp-enabled,
.fp-enabled body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*Avoid flicker on slides transitions for mobile phones #336 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box;
  /* <=28 */
  box-sizing: border-box;
  height: 100%;
  display: block; }

.fp-slide {
  float: left; }

.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block; }

.fp-slides {
  z-index: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease-out;
  /* Safari<=6 Android<=4.3 */
  transition: all 0.3s ease-out; }

.fp-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; }

.fp-slidesContainer {
  float: left;
  position: relative; }

.fp-controlArrow {
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
  position: absolute;
  z-index: 4;
  top: 50%;
  cursor: pointer;
  margin-top: -38px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fp-prev {
  left: 15px; }

.fp-next {
  right: 15px; }

.fp-arrow {
  width: 0;
  height: 0;
  border-style: solid; }

.fp-arrow.fp-prev {
  border-width: 38.5px 34px 38.5px 0;
  border-color: transparent #fff transparent transparent; }

.fp-arrow.fp-next {
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent #fff; }

.fp-notransition {
  -webkit-transition: none !important;
  transition: none !important; }

#fp-nav {
  position: fixed;
  z-index: 100;
  top: 50%;
  opacity: 1;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translate3d(0, -50%, 0); }

#fp-nav.fp-right {
  right: 17px; }

#fp-nav.fp-left {
  left: 17px; }

.fp-slidesNav {
  position: absolute;
  z-index: 4;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important; }

.fp-slidesNav.fp-bottom {
  bottom: 17px; }

.fp-slidesNav.fp-top {
  top: 17px; }

#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0; }

#fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative; }

.fp-slidesNav ul li {
  display: inline-block; }

#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none; }

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%; }

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0px 0px -5px; }

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer; }

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1; }

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px; }

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px; }

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide {
  height: auto !important; }

.fp-responsive .fp-is-overflow.fp-section {
  height: auto !important; }

/* Used with autoScrolling: false */
.fp-scrollable.fp-responsive .fp-is-overflow.fp-section,
.fp-scrollable .fp-section,
.fp-scrollable .fp-slide {
  /* Fallback for browsers that do not support Custom Properties */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100); }

.fp-scrollable.fp-responsive .fp-is-overflow.fp-section:not(.fp-auto-height):not([data-percentage]),
.fp-scrollable .fp-section:not(.fp-auto-height):not([data-percentage]),
.fp-scrollable .fp-slide:not(.fp-auto-height):not([data-percentage]) {
  /* Fallback for browsers that do not support Custom Properties */
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100); }

/* Disabling vertical centering on scrollable elements */
.fp-overflow {
  justify-content: flex-start;
  max-height: 100vh; }

/* No scrollable when using auto-height */
.fp-scrollable .fp-auto-height .fp-overflow {
  max-height: none; }

.fp-is-overflow .fp-overflow.fp-auto-height-responsive,
.fp-is-overflow .fp-overflow.fp-auto-height,
.fp-is-overflow > .fp-overflow {
  overflow-y: auto; }

.fp-overflow {
  outline: none; }

.fp-overflow.fp-table {
  display: block; }

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide {
  height: auto !important;
  min-height: auto !important; }

/*Only display content to screen readers*/
.fp-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.fp-scroll-mac .fp-overflow::-webkit-scrollbar {
  background-color: transparent;
  width: 9px; }

.fp-scroll-mac .fp-overflow::-webkit-scrollbar-track {
  background-color: transparent; }

.fp-scroll-mac .fp-overflow::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  border: 4px solid transparent; }

.fp-warning,
.fp-watermark {
  z-index: 9999999;
  position: absolute;
  bottom: 0; }

.fp-warning,
.fp-watermark a {
  text-decoration: none;
  color: #000;
  background: rgba(255, 255, 255, 0.6);
  padding: 5px 8px;
  font-size: 14px;
  font-family: arial;
  color: black;
  display: inline-block;
  border-radius: 3px;
  margin: 12px; }

.fp-noscroll .fp-overflow {
  overflow: hidden; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  transition: all .3s;
  color: #ffffff;
  border-radius: 4px;
  padding: 4px 24px;
  text-align: center; }
  .btn.btn--blue {
    background-color: #3e6ae1; }
    .btn.btn--blue:hover {
      background-color: #2560fe; }
  .btn.btn--border {
    color: #000000;
    box-shadow: inset 0 0 0 3px #3e6ae1; }
    .btn.btn--border:hover {
      color: #ffffff;
      background-color: #3e6ae1; }

footer {
  flex: 0 0 auto; }

.footerHome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ededed; }
  @media screen and (max-width: 461px) {
    .footerHome {
      justify-content: flex-start; } }
  .footerHome__top {
    width: 100%;
    max-width: 1400px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 100px 0 50px; }
    @media screen and (max-width: 1100px) {
      .footerHome__top {
        flex-wrap: wrap;
        padding: 20px 0; } }
    @media screen and (max-width: 461px) {
      .footerHome__top {
        margin: 10% 0; } }
    @media screen and (max-width: 376px) {
      .footerHome__top {
        margin: 10% auto auto; } }
    @media screen and (max-width: 1100px) {
      .footerHome__top-img {
        width: 100%;
        display: flex;
        justify-content: center;
        order: -1;
        text-align: center; }
        .footerHome__top-img img {
          max-width: 310px;
          flex: 0 0 auto; } }
    @media screen and (max-width: 769px) {
      .footerHome__top-img img {
        max-width: 270px; } }
    @media screen and (max-width: 461px) {
      .footerHome__top-img img {
        max-width: 180px;
        height: 230px; } }
    @media screen and (max-width: 376px) {
      .footerHome__top-img img {
        max-width: 160px;
        height: 207px; } }
    .footerHome__top-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      font-weight: 500;
      font-size: 15px;
      gap: 10px;
      padding: 12.5px 30px;
      color: rgba(0, 0, 0, 0.6);
      background-color: #ffffff;
      border-radius: 5px; }
      @media screen and (max-width: 769px) {
        .footerHome__top-btn {
          padding: 12.5px 15px; } }
      @media screen and (max-width: 461px) {
        .footerHome__top-btn {
          padding: 10px 15px;
          font-size: 14px; } }
      .footerHome__top-btn img {
        width: 30px;
        height: 30px;
        flex: 0 0 auto; }
        @media screen and (max-width: 461px) {
          .footerHome__top-btn img {
            width: 20px;
            height: 20px; } }
      .footerHome__top-btn:hover {
        background-color: #252525;
        color: #ffffff; }
    .footerHome__top-left {
      align-self: flex-start;
      text-align: right; }
      .footerHome__top-left .footerHome__top-title:after {
        left: auto;
        right: 0; }
        @media screen and (max-width: 461px) {
          .footerHome__top-left .footerHome__top-title:after {
            left: 50%; } }
      .footerHome__top-left .footerHome__top-text {
        margin-left: auto; }
    .footerHome__top-item {
      position: relative;
      flex: 0 0 380px;
      padding: 30px 0; }
      @media screen and (max-width: 1100px) {
        .footerHome__top-item {
          padding: 10px 0;
          flex: 0 0 calc(50% - 40px); } }
      @media screen and (max-width: 769px) {
        .footerHome__top-item {
          flex: 0 0 calc(50% - 20px); } }
      @media screen and (max-width: 461px) {
        .footerHome__top-item {
          text-align: center;
          flex: 0 0 100%; } }
    .footerHome__top-text {
      max-width: 280px;
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 25px; }
      @media screen and (max-width: 461px) {
        .footerHome__top-text {
          font-size: 14px;
          line-height: 19px;
          margin: 0 auto 10px; } }
    .footerHome__top-title {
      position: relative;
      font-weight: 600;
      font-size: 48px;
      line-height: 59px;
      margin-bottom: 10px;
      padding-bottom: 10px; }
      @media screen and (max-width: 769px) {
        .footerHome__top-title {
          font-size: 30px;
          line-height: 42px; } }
      @media screen and (max-width: 461px) {
        .footerHome__top-title {
          font-size: 24px;
          line-height: 30px; } }
      .footerHome__top-title:after {
        content: "";
        width: 170px;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2); }
        @media screen and (max-width: 461px) {
          .footerHome__top-title:after {
            left: 50%;
            transform: translateX(-50%); } }
    .footerHome__top-after {
      position: absolute;
      background: linear-gradient(90.06deg, #000000 9.53%, rgba(0, 0, 0, 0.37) 63.02%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 0.05;
      font-weight: 600;
      font-size: 200px;
      line-height: 244px; }
      @media screen and (max-width: 1200px) {
        .footerHome__top-after {
          display: none; } }
    .footerHome__top-right {
      align-self: flex-end; }
      @media screen and (max-width: 1025px) {
        .footerHome__top-right {
          align-self: flex-start; } }
      .footerHome__top-right .footerHome__top-after {
        bottom: 90%;
        left: -200px; }
  .footerHome__bottom {
    background-color: #252525;
    padding: 50px 15px; }
    @media screen and (max-width: 1100px) {
      .footerHome__bottom {
        padding: 25px 15px;
        margin-bottom: 70px; } }
    @media screen and (max-width: 461px) {
      .footerHome__bottom {
        display: none; } }
    .footerHome__bottom-inner {
      width: 100%;
      max-width: 1400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 auto;
      color: #ffffff; }
      @media screen and (max-width: 1100px) {
        .footerHome__bottom-inner {
          gap: 20px; } }
    .footerHome__bottom-socials {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      font-weight: 500;
      font-size: 15px; }
      .footerHome__bottom-socials a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #ffffff; }
      .footerHome__bottom-socials img {
        width: 30px;
        height: 30px;
        flex: 0 0 auto; }
    .footerHome__bottom-phone {
      display: flex;
      flex-direction: column;
      gap: 10px; }
      .footerHome__bottom-phone a {
        color: #ffffff;
        font-size: 20px; }
    .footerHome__bottom-address {
      font-size: 15px;
      color: rgba(255, 255, 255, 0.5); }
    .footerHome__bottom-left {
      font-weight: 600;
      font-size: 24px; }
      @media screen and (max-width: 1100px) {
        .footerHome__bottom-left {
          display: none; } }

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 32px; }
  .header.header--hide {
    display: none; }
  .header.header--fixed {
    position: fixed;
    z-index: 1000;
    width: 100%;
    left: 0;
    top: 0; }
  .header.header--white .headerNav__link, .header.header--white .headerNav__item {
    color: #ffffff; }
  .header.header--white .modal-phone__wrapper:not(:last-child) {
    border-bottom-color: #ffffff; }
  .header.header--white .modal-phone__item {
    color: #ffffff; }
    .header.header--white .modal-phone__item:hover {
      background-color: #886d58; }
  .header.header--white .modal-phone__icon {
    fill: #ffffff; }
  .header.header--blur {
    backdrop-filter: blur(16px); }
  .header__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  @media screen and (max-width: 700px) {
    .header {
      padding: 0 24px; } }
  @media screen and (max-width: 414px) {
    .header {
      padding: 0 15px; } }

.logo {
  display: block;
  max-width: 120px; }

.sandwich {
  display: none; }
  @media screen and (max-width: 1100px) {
    .sandwich {
      display: block; } }
  .sandwich__icon {
    width: 22px;
    height: 18px; }

.headerNav {
  font-family: 'Gotham', sans-serif; }
  .headerNav__list {
    display: flex; }
  .headerNav__phone {
    position: relative; }
    .headerNav__phone:hover .modal-phone {
      visibility: visible;
      pointer-events: all;
      opacity: 1;
      transform: translateY(0); }
  .headerNav__item {
    font-size: 14px;
    cursor: pointer;
    color: #000000;
    padding: 4px 16px;
    height: 32px;
    line-height: 24px;
    transition: all .35s ease-in-out; }
    @media screen and (max-width: 500px) {
      .headerNav__item {
        border-radius: 4px;
        backdrop-filter: blur(16px);
        background-color: rgba(0, 0, 0, 0.05); } }
  .headerNav__link {
    color: #000000; }
  .headerNav__backdrop {
    position: absolute;
    z-index: -1;
    backdrop-filter: blur(16px);
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    transform: translateX(-60px);
    transition: all .35s ease-in-out; }
  @media screen and (max-width: 500px) {
    .headerNav .headerNav__phone {
      display: none; } }
  .headerNav.hidden {
    display: block; }
    @media screen and (max-width: 1000px) {
      .headerNav.hidden {
        display: none; } }

.homePages {
  height: 100vh; }
  .homePages__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 17vh 24px 7vh;
    font-family: 'Copyright', sans-serif; }
    @media screen and (max-width: 461px) {
      .homePages__inner {
        padding: 15vh 15px 7vh; } }
  .homePages__socials {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 40px; }
    .homePages__socials > *:not(:last-child) {
      margin-right: 20px; }
    @media screen and (max-width: 430px) {
      .homePages__socials {
        margin-bottom: 20px; } }
    .homePages__socials a {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      color: #252525; }
      .homePages__socials a > *:not(:last-child) {
        margin-right: 10px; }
      .homePages__socials a img {
        width: 40px;
        height: 40px; }
        @media screen and (max-width: 375px) {
          .homePages__socials a img {
            width: 30px;
            height: 30px; } }
  .homePages__location {
    display: flex;
    flex-direction: column;
    padding: 24px 30px;
    height: 100vh;
    color: #252525; }
    @media screen and (max-width: 375px) {
      .homePages__location {
        padding: 24px 15px; } }
    .homePages__location-after {
      background: linear-gradient(90.04deg, #000000 9.52%, rgba(0, 0, 0, 0.37) 28.05%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 0.05;
      font-weight: 600;
      font-size: 50px;
      line-height: 85px; }
      @media screen and (max-width: 414px) {
        .homePages__location-after {
          font-size: 40px;
          line-height: 55px; } }
    .homePages__location-title {
      font-weight: 600;
      font-size: 30px;
      margin-bottom: 36px;
      padding-left: 25px; }
  .homePages__links {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transition: all .8s ease-in-out; }
  .homePages__link {
    border-radius: 4px;
    height: 40px;
    text-align: center;
    flex: 1;
    padding: 0 24px;
    font-size: 14px;
    font-family: 'Gotham', sans-serif;
    line-height: 40px; }
    .homePages__link:not(:last-child) {
      margin-right: 32px; }
    @media screen and (max-width: 461px) {
      .homePages__link {
        flex: 0 0 100%; }
        .homePages__link:not(:last-child) {
          margin-bottom: 15px;
          margin-right: 0; } }
    .homePages__link:first-child {
      background-color: rgba(23, 26, 32, 0.8);
      color: #ffffff; }
    .homePages__link:last-child {
      color: #393c41;
      background-color: rgba(244, 244, 244, 0.65);
      backdrop-filter: blur(8px); }
  .homePages__item {
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .homePages__item:nth-child(7), .homePages__item:nth-child(8) {
      height: auto; }
    .homePages__item:nth-child(6) .homePages__title {
      color: #ffffff; }
    .homePages__item.active .homePages__title {
      opacity: 1;
      transform: scale(1); }
    .homePages__item.active .homePages__links {
      transform: translateY(0); }
    .homePages__item.homePages--location {
      display: none; }
      @media screen and (max-width: 414px) {
        .homePages__item.homePages--location {
          display: block; } }
  .homePages__bottom {
    margin: auto 0 0; }
    @media screen and (max-width: 376px) {
      .homePages__bottom {
        margin-top: 0; } }
  .homePages__title {
    font-size: 40px;
    font-family: 'GothamPro', sans-serif;
    font-weight: 500;
    transition: all 0.8s; }
    @media screen and (max-width: 414px) {
      .homePages__title {
        font-size: 34px; } }
  .homePages__img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1; }
    @media screen and (max-width: 1281px) {
      .homePages__img img {
        width: 120%;
        max-width: 120%;
        margin-left: -10%; } }
    @media screen and (max-width: 1025px) {
      .homePages__img img {
        width: 100%;
        max-width: 100%;
        margin-left: 0; } }

@media screen and (max-width: 376px) {
  .homePageTabs {
    margin-bottom: 30%; } }

.homePageTabs__top {
  position: relative;
  z-index: 2;
  display: flex;
  border-bottom: 2px solid rgba(155, 155, 155, 0.3);
  margin-bottom: 25px; }

.homePageTabs__item {
  position: relative;
  z-index: 1;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  line-height: 25px; }
  .homePageTabs__item > *:not(:last-child) {
    margin-bottom: 20px; }
  .homePageTabs__item.active {
    display: flex; }

.homePageTabs__map {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: -1; }
  @media screen and (max-width: 414px) {
    .homePageTabs__map {
      top: -30px; } }
  @media screen and (max-width: 375px) {
    .homePageTabs__map {
      right: -15px; } }

.homePageTabs__btn {
  font-weight: 500;
  font-size: 15px;
  border-radius: 4px;
  background-color: #252525;
  color: #ffffff;
  padding: 10px 15px; }

.homePageTabs__phone {
  color: #252525; }

.homePageTabs__address {
  max-width: 210px; }

.homePageTabs__tab {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  flex: 1;
  padding-bottom: 5px;
  color: rgba(37, 37, 37, 0.3); }
  .homePageTabs__tab:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: transparent; }
  .homePageTabs__tab.active {
    color: #252525; }
    .homePageTabs__tab.active:after {
      background-color: #252525; }

.copyright {
  position: relative;
  z-index: 1;
  font-size: 12px;
  text-align: center;
  color: rgba(37, 37, 37, 0.6); }
  .copyright a {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    color: rgba(37, 37, 37, 0.6); }

#roadster.pg-active .homePages__title {
  color: #ffffff; }

.detailsCar__title {
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  margin-bottom: 50px; }
  @media screen and (max-width: 1367px) {
    .detailsCar__title {
      font-size: 36px;
      line-height: 42px; } }
  @media screen and (max-width: 1200px) {
    .detailsCar__title {
      font-size: 24px;
      line-height: 32px; } }

@media screen and (max-width: 1100px) {
  .detailsCar.modelPrices .detailsCar__slider {
    margin-bottom: 50px; } }

.detailsCar.modelPrices .detailsCar__title {
  text-align: left;
  font-weight: bold;
  color: #2B2B2B; }
  @media screen and (max-width: 1100px) {
    .detailsCar.modelPrices .detailsCar__title {
      text-align: center;
      margin-bottom: 30px;
      padding: 0 15px; } }

@media screen and (max-width: 1100px) {
  .detailsCar.modelPrices .openGallery, .detailsCar.modelPrices .outputPrice {
    width: 80%;
    max-width: 550px;
    position: relative;
    text-align: center;
    justify-content: center;
    border-radius: 0 0 20px 20px;
    padding: 12px; } }

@media screen and (max-width: 700px) {
  .detailsCar.modelPrices .openGallery, .detailsCar.modelPrices .outputPrice {
    max-width: 300px; } }

@media screen and (max-width: 414px) {
  .detailsCar.modelPrices .openGallery, .detailsCar.modelPrices .outputPrice {
    font-size: 14px;
    gap: 10px; } }

@media screen and (max-width: 1100px) {
  .detailsCar.modelPrices .openGallery__btn {
    width: 30px;
    height: 30px; } }

@media screen and (max-width: 1100px) {
  .detailsCar.modelPrices .openGallery__icon {
    width: 18px;
    height: 18px;
    opacity: 1;
    transform: rotate(180deg); } }

.detailsCar__mod {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  margin-bottom: 30px; }
  @media screen and (max-width: 1200px) {
    .detailsCar__mod {
      font-size: 12px;
      margin-bottom: 20px; } }

.detailsCar__prices {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 30px;
  background-color: #f4f4f4;
  margin-bottom: 37px; }
  @media screen and (max-width: 1200px) {
    .detailsCar__prices {
      padding: 20px; } }

.detailsCar__info {
  padding-right: 70px;
  overflow: hidden auto;
  margin-right: -20px;
  padding-bottom: 20px; }
  @media screen and (max-width: 1100px) {
    .detailsCar__info .detailsCar__title {
      display: none; } }
  @media screen and (max-width: 1367px) {
    .detailsCar__info {
      padding-right: 35px; } }
  @media screen and (max-width: 1200px) {
    .detailsCar__info {
      padding-right: 30px; } }
  @media screen and (max-width: 1100px) {
    .detailsCar__info {
      padding-right: 34px;
      padding-left: 34px;
      margin-right: 0;
      padding-bottom: 80px; } }
  @media screen and (max-width: 414px) {
    .detailsCar__info {
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 50px; } }

.detailsCar__feature {
  display: flex;
  gap: 27px;
  margin-bottom: 35px;
  justify-content: space-between; }
  @media screen and (max-width: 1200px) {
    .detailsCar__feature {
      margin-bottom: 20px;
      gap: 10px; } }
  @media screen and (max-width: 1100px) {
    .detailsCar__feature {
      max-width: 600px;
      margin: 0 auto 20px; } }
  .detailsCar__feature li {
    text-align: center;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 1200px) {
      .detailsCar__feature li {
        font-size: 10px; } }
    .detailsCar__feature li span {
      display: block;
      color: #393c41;
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 5px; }
      @media screen and (max-width: 1200px) {
        .detailsCar__feature li span {
          font-size: 18px; } }

.detailsCar__inner {
  display: grid;
  grid-template-columns: 1fr 550px;
  gap: 30px;
  overflow: hidden;
  height: calc(100vh - 60px); }
  @media screen and (max-width: 1367px) {
    .detailsCar__inner {
      grid-template-columns: 1fr 400px; } }
  @media screen and (max-width: 1200px) {
    .detailsCar__inner {
      grid-template-columns: 1fr 320px; } }
  @media screen and (max-width: 1100px) {
    .detailsCar__inner {
      grid-template-columns: 1fr;
      height: auto; } }

.detailsCar__slider {
  position: sticky;
  top: 0;
  overflow: hidden; }
  @media screen and (max-width: 1100px) {
    .detailsCar__slider {
      position: static; } }

.modelPrices__list {
  margin-bottom: 75px; }
  @media screen and (max-width: 1367px) {
    .modelPrices__list {
      margin-bottom: 45px; } }
  @media screen and (max-width: 1100px) {
    .modelPrices__list {
      display: flex;
      flex-direction: column;
      align-items: center; } }

.modelPrices__inner {
  display: flex;
  margin-bottom: 60px; }
  @media screen and (max-width: 1100px) {
    .modelPrices__inner {
      justify-content: center; } }
  @media screen and (max-width: 414px) {
    .modelPrices__inner {
      width: calc(100% + 30px);
      margin: 0 -15px 40px; } }

.modelPrices__btn {
  background: #2B2B2B;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.8);
  height: 48px;
  padding: 0 12px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  transition: color .3s; }
  .modelPrices__btn:hover {
    color: #ffffff; }
    @media screen and (max-width: 1100px) {
      .modelPrices__btn:hover {
        background-color: #2B2B2B; } }
  @media screen and (max-width: 1100px) {
    .modelPrices__btn {
      background-color: #ffffff;
      color: #2B2B2B;
      border: 2px solid #2B2B2B;
      font-weight: 500; } }
  @media screen and (max-width: 414px) {
    .modelPrices__btn {
      font-size: 16px;
      height: 44px; } }

.modelPrices__item {
  display: flex;
  flex-direction: column;
  color: #2B2B2B;
  flex: 1;
  font-weight: 500; }
  .modelPrices__item:first-child .modelPrices__col {
    padding-left: 20px; }
    @media screen and (max-width: 414px) {
      .modelPrices__item:first-child .modelPrices__col {
        padding-left: 10px; } }
    .modelPrices__item:first-child .modelPrices__col:first-child {
      border-top-left-radius: 5px; }
      @media screen and (max-width: 414px) {
        .modelPrices__item:first-child .modelPrices__col:first-child {
          border-top-left-radius: 0; } }
    .modelPrices__item:first-child .modelPrices__col:last-child {
      border-bottom-left-radius: 5px; }
      @media screen and (max-width: 414px) {
        .modelPrices__item:first-child .modelPrices__col:last-child {
          border-bottom-left-radius: 0; } }
  .modelPrices__item:last-child .modelPrices__col {
    padding-right: 20px; }
    .modelPrices__item:last-child .modelPrices__col:first-child {
      border-top-right-radius: 5px; }
      @media screen and (max-width: 414px) {
        .modelPrices__item:last-child .modelPrices__col:first-child {
          border-top-right-radius: 0; } }
    @media screen and (max-width: 414px) {
      .modelPrices__item:last-child .modelPrices__col:last-child {
        border-bottom-right-radius: 0; } }

.modelPrices__col {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  white-space: nowrap;
  background: #DDDDDD;
  flex: 1; }
  .modelPrices__col:nth-child(odd) {
    background: #EFEFEF; }
  @media screen and (max-width: 1367px) {
    .modelPrices__col {
      font-size: 14px;
      padding: 10px; } }
  @media screen and (max-width: 375px) {
    .modelPrices__col {
      font-size: 12px; } }

.swiperDetails, .swiperConfig, .swiperModel {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden; }
  @media screen and (max-width: 1100px) {
    .swiperDetails, .swiperConfig, .swiperModel {
      position: static; } }
  .swiperDetails:hover .swiperDetails__arrows, .swiperConfig:hover .swiperDetails__arrows, .swiperModel:hover .swiperDetails__arrows {
    width: 100%;
    opacity: 1;
    pointer-events: all; }
  .swiperDetails__arrows {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
    z-index: 10; }
    @media screen and (max-width: 1100px) {
      .swiperDetails__arrows {
        display: none; } }
  .swiperDetails__icon {
    width: 28px;
    height: 28px;
    border-radius: 5px;
    padding: 5px;
    background-color: #eee;
    cursor: pointer; }
  .swiperDetails__arrow {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 40px; }

.detailsPayment {
  display: flex;
  flex-direction: column;
  gap: 10px; }
  .detailsPayment__item {
    position: relative;
    display: flex;
    align-self: flex-start;
    gap: 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 12px; }
    @media screen and (max-width: 500px) {
      .detailsPayment__item {
        margin-top: 20px; } }
    .detailsPayment__item span {
      font-size: 14px; }
      @media screen and (max-width: 500px) {
        .detailsPayment__item span {
          position: absolute;
          left: 0;
          top: -20px; } }
  .detailsPayment__rub, .detailsPayment__usd {
    font-weight: 600;
    font-size: 16px; }

.detailsPrice-cash {
  font-size: 14px; }

.detailsPrice-rub {
  font-weight: 600;
  font-size: 40px; }
  @media screen and (max-width: 1200px) {
    .detailsPrice-rub {
      font-size: 32px; } }
  @media screen and (max-width: 414px) {
    .detailsPrice-rub {
      font-size: 26px; } }

.detailsPrice-usd {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4); }

.detailsPrice__btn {
  align-self: flex-start;
  background: #2B2B2B;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  transition: all .3s;
  cursor: pointer;
  padding: 12px;
  color: #ffffff; }
  .detailsPrice__btn:hover {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.3); }

.qualitiesCar {
  margin-bottom: 37px; }
  .qualitiesCar__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 10px; }
    @media screen and (max-width: 500px) {
      .qualitiesCar__title {
        font-size: 16px; } }
  .qualitiesCar__item {
    position: relative;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    padding-left: 15px; }
    @media screen and (max-width: 500px) {
      .qualitiesCar__item {
        font-size: 12px;
        line-height: 24px; } }
    .qualitiesCar__item:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: #000000; }

.openGallery, .outputPrice {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  z-index: 100;
  cursor: pointer;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 40px;
  font-weight: 500;
  bottom: 0;
  background: #f4f4f4;
  border-radius: 10px;
  border-radius: 10px 10px 0 0; }
  @media screen and (max-width: 1100px) {
    .openGallery, .outputPrice {
      position: fixed; } }
  @media screen and (max-width: 500px) {
    .openGallery, .outputPrice {
      padding: 15px 25px; } }
  .openGallery__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    flex: 0 0 auto;
    border-radius: 100%;
    background-color: #ffffff;
    transition: all .3s;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07); }
    @media screen and (max-width: 500px) {
      .openGallery__btn {
        width: 30px;
        height: 30px; } }
    .openGallery__btn:hover {
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2)); }
  .openGallery__icon {
    width: 24px;
    height: 24px;
    opacity: 0.4; }
    @media screen and (max-width: 500px) {
      .openGallery__icon {
        width: 20px;
        height: 20px; } }

.outputPrice {
  gap: 5px;
  color: #393c41;
  font-weight: 600; }
  @media screen and (max-width: 414px) {
    .outputPrice {
      font-size: 14px;
      padding: 10px 15px; } }

.tabPrices {
  margin-top: 50px;
  font-family: 'Gotham', sans-serif;
  font-weight: 500; }
  .tabPrices__inner {
    display: flex;
    height: 40px;
    border-radius: 4px;
    gap: 4px;
    padding: 4px;
    background-color: rgba(150, 150, 150, 0.1);
    color: #5c5e62;
    font-size: 12px; }
  .tabPrices__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    flex: 1;
    transition: all .3s;
    cursor: pointer;
    border-radius: 4px; }
    .tabPrices__tab.active {
      background-color: #ffffff;
      color: #171a20;
      box-shadow: 0px 1px 9px -6px rgba(0, 0, 0, 0.5); }
  .tabPrices__item {
    display: none; }
    .tabPrices__item.active {
      display: block; }
  .tabPrices__content {
    font-size: 14px;
    padding: 16px 0;
    color: #393c41; }
  .tabPrices__currency, .tabPrices__lease, .tabPrices__loan {
    display: flex;
    gap: 20px;
    justify-content: space-between; }
    .tabPrices__currency:not(:last-child), .tabPrices__lease:not(:last-child), .tabPrices__loan:not(:last-child) {
      margin-bottom: 10px; }

.modal-cars {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1000; }
  .modal-cars.active {
    display: block; }
  .modal-cars__inner {
    display: flex;
    align-items: center; }

.modalCars {
  padding: 0 20px; }
  .modalCars__slide {
    max-width: 600px;
    position: relative;
    border-radius: 20px;
    overflow: hidden; }
    .modalCars__slide:not(.swiper-slide-active) {
      filter: brightness(0.5); }
  .modalCars__close {
    position: absolute;
    width: 40px;
    height: 40px;
    padding: 10px;
    top: 0;
    right: 0;
    cursor: pointer; }
  .modalCars__icon {
    max-width: 100%;
    height: 100%; }
  .modalCars__arrows {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    position: absolute;
    left: 50%;
    top: calc(50% - 30px);
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 600px) {
      .modalCars__arrows {
        width: calc(100% - 40px); } }
    @media screen and (max-width: 414px) {
      .modalCars__arrows {
        display: none; } }
  .modalCars__dots {
    text-align: center;
    padding-top: 30px; }
    @media screen and (max-width: 600px) {
      .modalCars__dots {
        padding-top: 15px; } }
    @media screen and (max-width: 414px) {
      .modalCars__dots {
        padding-top: 10px; } }
  .modalCars__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    transition: all .3s;
    background-color: #a1a2a4; }
    @media screen and (max-width: 600px) {
      .modalCars__arrow {
        padding: 8px;
        width: 40px;
        height: 40px; } }
    .modalCars__arrow.swiper-button-disabled {
      opacity: 0; }
    .modalCars__arrow svg {
      fill: #ffffff;
      height: 100%; }

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ffffff; }

.configCar .detailsCar__inner {
  grid-template-columns: 1fr 370px; }
  @media screen and (max-width: 1100px) {
    .configCar .detailsCar__inner {
      grid-template-columns: 1fr; } }

.prices__item:not(:last-child) {
  margin-bottom: 24px; }

.prices__input:checked + label {
  box-shadow: inset 0 0 0 3px #3e6ae1;
  color: #000000; }

.prices__title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px; }

.prices__label {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  flex-flow: row wrap;
  border-radius: 4px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  height: 48px;
  padding: 0 20px;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px #a2a3a5;
  transition: all 0.33s cubic-bezier(0.5, 0, 0, 0.75);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent; }

.prices__info {
  color: #5c5e62;
  font-size: 12px;
  text-align: center;
  line-height: 17px;
  font-weight: 500;
  margin-top: 5px; }

.openSlider {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  opacity: 0;
  pointer-events: none; }
  @media screen and (max-width: 414px) {
    .openSlider {
      margin: 20px 0; } }
  .openSlider__btn {
    display: flex;
    align-items: center;
    text-align: center;
    height: 40px;
    font-weight: 600;
    border-radius: 4px;
    background-color: #f4f4f4;
    padding: 0 20px;
    cursor: pointer;
    font-size: 14px;
    transition: all .3s; }
    .openSlider__btn:hover {
      background-color: #eee; }

.filterCar__look {
  position: relative; }
  .filterCar__look:not(:last-child) {
    margin-bottom: 150px; }
    @media screen and (max-width: 769px) {
      .filterCar__look:not(:last-child) {
        margin-bottom: 100px; } }
  @media screen and (max-width: 769px) {
    .filterCar__look.paint .filterCar__mobile img {
      max-width: 140%;
      margin: -20%; } }
  @media screen and (max-width: 414px) {
    .filterCar__look.Interior {
      margin-bottom: 70px; } }
  @media screen and (max-width: 769px) {
    .filterCar__look.Interior .filterCar__mobile {
      margin-bottom: 40px; } }
  @media screen and (max-width: 414px) {
    .filterCar__look.Interior .filterCar__mobile {
      margin-bottom: 20px; } }
  .filterCar__look.wheels .filterCar__item:nth-child(3) {
    display: none; }

.filterCar__mobile {
  display: none;
  overflow: hidden;
  margin: 0 -34px; }
  @media screen and (max-width: 769px) {
    .filterCar__mobile {
      display: block; } }
  @media screen and (max-width: 414px) {
    .filterCar__mobile {
      margin: 0 -15px; } }

.filterCar__title, .orderCar__title, .featureCar__title {
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 500;
  font-family: 'Gotham', sans-serif; }
  @media screen and (max-width: 414px) {
    .filterCar__title, .orderCar__title, .featureCar__title {
      font-size: 20px;
      margin-bottom: 20px; } }

.filterCar__inner {
  display: flex;
  justify-content: center; }

.filterCar__item {
  width: 54px;
  height: 54px;
  padding: 4px;
  margin: 0 4px; }

.filterCar__label {
  display: inline-block;
  position: relative;
  cursor: pointer; }
  .filterCar__label:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .3s;
    width: 58px;
    height: 58px;
    border-radius: 100%;
    opacity: 0;
    box-shadow: inset 0 0 0 3px #3e6ae1; }

.filterCar__input {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  pointer-events: none; }
  .filterCar__input:checked + label:after {
    opacity: 1; }

.filterCar__value {
  width: 100%;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Gotham', sans-serif;
  margin-top: 16px;
  padding: 0 20px; }

.orderCar {
  margin-top: 50px; }
  .orderCar__date {
    text-align: center;
    font-family: 'Gotham', sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 24px; }
  .orderCar a:not(:last-child) {
    margin-bottom: 10px; }

.featureCar {
  margin-top: 100px; }
  .featureCar__list {
    padding-left: 24px;
    margin-left: 5px; }
  .featureCar__item {
    list-style: disc;
    font-size: 14px;
    padding: 10px 5px; }
    .featureCar__item span {
      padding: 0 5px; }

.modelOption {
  margin-bottom: 50px; }
  @media screen and (max-width: 414px) {
    .modelOption {
      margin: 0 -15px 30px; } }
  .modelOption__item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #1E1E1E;
    padding: 15px 20px;
    font-size: 18px; }
    @media screen and (max-width: 1367px) {
      .modelOption__item {
        font-size: 14px;
        padding: 10px 15px; } }
    .modelOption__item:last-child {
      border-bottom: 1px solid #1E1E1E; }
  .modelOption__name {
    font-weight: 500; }
  .modelOption__value {
    flex-basis: 30%; }

.modelDetail {
  margin-bottom: 250px; }
  @media screen and (max-width: 1100px) {
    .modelDetail {
      margin-bottom: 100px; } }
  .modelDetail[data-model="corps"] .modelDetail__caption {
    overflow: hidden; }
    .modelDetail[data-model="corps"] .modelDetail__caption:first-child img {
      max-width: 140%;
      margin: -20%; }
  .modelDetail__caption {
    display: none;
    margin: 0 -34px 50px; }
    @media screen and (max-width: 1100px) {
      .modelDetail__caption {
        display: block; } }
    @media screen and (max-width: 414px) {
      .modelDetail__caption {
        margin: 0 -15px 30px; } }
  .modelDetail__title {
    font-size: 45px;
    font-weight: bold;
    color: #2B2B2B;
    margin-bottom: 50px; }
    @media screen and (max-width: 1367px) {
      .modelDetail__title {
        font-size: 30px;
        margin-bottom: 20px; } }
    @media screen and (max-width: 1100px) {
      .modelDetail__title {
        text-align: center; } }
    @media screen and (max-width: 414px) {
      .modelDetail__title {
        font-size: 24px; } }
  .modelDetail__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 1px solid #333333;
    padding: 16px; }
    @media screen and (max-width: 1367px) {
      .modelDetail__img {
        padding: 10px;
        width: 100px;
        height: 100px; } }
    @media screen and (max-width: 414px) {
      .modelDetail__img {
        padding: 5px;
        width: 60px;
        height: 60px; } }
    .modelDetail__img img {
      width: 100%;
      height: 100%; }
  .modelDetail__type {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;
    font-size: 28px; }
    @media screen and (max-width: 1100px) {
      .modelDetail__type {
        margin: 0 auto;
        font-size: 20px;
        gap: 10px; } }

.modelMobile {
  display: none; }
  @media screen and (max-width: 1100px) {
    .modelMobile {
      display: block; } }
  .modelMobile__caption img {
    position: relative;
    z-index: -1;
    max-width: 140%;
    margin: -22% -20% -10%; }
    @media screen and (max-width: 414px) {
      .modelMobile__caption img {
        max-width: 180%;
        margin: -35% -40% -10%; } }

.modelForm {
  max-width: 405px;
  margin-bottom: 50px; }
  .modelForm .socials {
    margin-top: 50px; }
    @media screen and (max-width: 1100px) {
      .modelForm .socials {
        justify-content: center;
        gap: 50px; } }
  @media screen and (max-width: 1100px) {
    .modelForm {
      margin: 0 auto; } }
  .modelForm__title {
    font-weight: 700;
    font-size: 45px;
    line-height: 120%;
    margin-bottom: 20px; }
    @media screen and (max-width: 1367px) {
      .modelForm__title {
        text-align: center;
        font-size: 36px; } }
    @media screen and (max-width: 414px) {
      .modelForm__title {
        font-size: 28px;
        margin-bottom: 5px; } }
    @media screen and (max-width: 375px) {
      .modelForm__title {
        font-size: 24px; } }
  .modelForm__text {
    font-size: 20px;
    line-height: 120%; }
    @media screen and (max-width: 1100px) {
      .modelForm__text {
        text-align: center; } }
    @media screen and (max-width: 414px) {
      .modelForm__text {
        font-size: 18px; } }
    @media screen and (max-width: 375px) {
      .modelForm__text {
        font-size: 16px; } }
  .modelForm__form {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    gap: 10px; }
    @media screen and (max-width: 1367px) {
      .modelForm__form {
        margin-top: 35px; } }
    @media screen and (max-width: 414px) {
      .modelForm__form {
        max-width: 275px;
        margin: 35px auto 0; } }
  .modelForm__check {
    order: 1;
    padding-left: 40px; }
    @media screen and (max-width: 1100px) {
      .modelForm__check {
        font-size: 14px; } }
    @media screen and (max-width: 414px) {
      .modelForm__check {
        font-size: 12px; } }
    .modelForm__check-icon {
      position: absolute;
      left: -36px;
      top: 4px;
      transform: scale(0);
      transition: all .3s;
      width: 12px;
      height: 12px; }
    .modelForm__check input {
      position: absolute;
      width: 1px;
      height: 1px;
      visibility: hidden;
      opacity: 0; }
      .modelForm__check input + label {
        position: relative;
        cursor: pointer; }
        .modelForm__check input + label:before {
          content: '';
          position: absolute;
          left: -40px;
          top: 0;
          width: 20px;
          height: 20px;
          background: #EFEFEF;
          border-radius: 4px; }
      .modelForm__check input:checked + label .modelForm__check-icon {
        transform: scale(1); }
  .modelForm__btn {
    font-weight: 500;
    font-size: 20px;
    height: 48px;
    border-radius: 5px;
    padding: 0 48px;
    margin: 50px 0;
    color: #000000;
    align-self: flex-start;
    border: 2px solid #000000;
    transition: all .3s; }
    @media screen and (max-width: 1100px) {
      .modelForm__btn {
        width: 100%;
        margin: 30px 0 20px; } }
    @media screen and (max-width: 414px) {
      .modelForm__btn {
        height: 44px;
        font-size: 16px; } }
    .modelForm__btn:hover {
      background-color: #000000;
      color: #ffffff; }
  .modelForm__input {
    width: 100%;
    background: #EFEFEF;
    border-radius: 5px;
    height: 70px;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    color: #000000;
    font-weight: 200;
    padding: 0 20px; }
    @media screen and (max-width: 1100px) {
      .modelForm__input {
        font-size: 16px;
        height: 48px; } }
    .modelForm__input::placeholder {
      color: #000000;
      transition: all .3s; }
    .modelForm__input:focus::placeholder {
      opacity: 0; }

.socials {
  display: flex;
  align-items: center;
  gap: 20px; }
  .socials__item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 15px;
    color: #252525;
    transition: color .3s; }
    .socials__item.whatsapp:hover {
      color: #01bf65; }
      .socials__item.whatsapp:hover .socials__icon {
        fill: #01bf65; }
    .socials__item.telegram:hover {
      color: #15b4f0; }
      .socials__item.telegram:hover .socials__icon {
        fill: #15b4f0; }
  .socials__icon {
    width: 34px;
    height: 34px;
    fill: #252525;
    transition: fill .3s; }

.thumbSwiper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%; }
  @media screen and (max-width: 1100px) {
    .thumbSwiper {
      height: auto;
      flex-direction: column;
      align-items: center; } }
  .thumbSwiper__inner {
    display: flex;
    position: absolute;
    gap: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    bottom: 45px;
    padding: 35px 20px 50px;
    background: #F4F4F4; }
    @media screen and (max-width: 1367px) {
      .thumbSwiper__inner {
        padding: 20px 20px 40px; } }
    @media screen and (max-width: 1100px) {
      .thumbSwiper__inner {
        position: static;
        transform: translate(0, 0);
        padding: 20px; } }
    @media screen and (max-width: 700px) {
      .thumbSwiper__inner {
        gap: 7px;
        padding: 20px 15px; } }
    @media screen and (max-width: 360px) {
      .thumbSwiper__inner {
        width: 100%;
        gap: 5px;
        justify-content: center; } }
  .thumbSwiper__item {
    cursor: pointer;
    width: 110px;
    height: 110px;
    border-radius: 6px;
    overflow: hidden;
    border: 2px solid transparent;
    transition: all .3s;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08)); }
    @media screen and (max-width: 1367px) {
      .thumbSwiper__item {
        width: 80px;
        height: 80px; } }
    @media screen and (max-width: 700px) {
      .thumbSwiper__item {
        width: 60px;
        height: 60px; } }
    @media screen and (max-width: 360px) {
      .thumbSwiper__item {
        width: 50px;
        height: 50px; } }
    .thumbSwiper__item.active {
      border-color: #d9d9d9; }

@media screen and (max-width: 769px) {
  .swiperConfig .swiperDetails__slide {
    margin-right: 20%; } }

@media screen and (min-width: 1100px) {
  .swiperConfig .swiperDetails__slide img {
    max-width: 120%;
    margin-left: -10%; } }

@media screen and (max-width: 769px) {
  .swiperConfig .swiperDetails__slide img {
    max-width: 140%;
    margin: -20%; } }

@media screen and (max-width: 1100px) {
  .swiperModel {
    margin-bottom: 50px; } }

@media screen and (max-width: 414px) {
  .swiperModel {
    margin-bottom: 30px; } }

.swiperModel__dots.swiper-pagination-horizontal {
  bottom: 270px; }
  @media screen and (max-width: 1367px) {
    .swiperModel__dots.swiper-pagination-horizontal {
      bottom: 220px; } }

@media screen and (max-width: 1100px) {
  .swiperModel__slide {
    overflow: hidden; } }

@media screen and (max-width: 1367px) {
  .swiperModel__slide img {
    max-width: 140%;
    margin: -10% -20%; } }

.swiperModel__dots {
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 20; }
  @media screen and (max-width: 1100px) {
    .swiperModel__dots {
      display: none; } }

.swiperModel__dot {
  width: 22px;
  height: 6px;
  border-radius: 6px;
  background-color: #6C6C6C;
  cursor: pointer;
  transition: all .3s; }
  .swiperModel__dot.swiperModel__dot-active {
    background-color: #252525; }

a.sunNews__recommend img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  object-fit: cover;
  z-index: -1; }

.modal-phone {
  position: absolute;
  z-index: 10;
  width: calc(100% + 25px);
  display: flex;
  justify-content: space-between;
  left: 0;
  top: calc(100% + 10px);
  transform: translateY(20px);
  transition: transform 0.3s !important;
  padding: 0 12px;
  flex-direction: column;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  border-radius: 4px; }
  .modal-phone:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    border-radius: 4px;
    backdrop-filter: blur(16px);
    background-color: rgba(0, 0, 0, 0.05); }
  .modal-phone:before {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    height: 10px; }
  .modal-phone__wrapper:not(:last-child) {
    border-bottom: 1px solid #000000; }
  .modal-phone__wrapper:first-child .modal-phone__item {
    border-radius: 4px 4px 0 0; }
  .modal-phone__wrapper:last-child .modal-phone__item {
    border-radius: 0 0 4px 4px; }
  .modal-phone__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: #393c41;
    font-size: 12px;
    margin: 0 -12px;
    transition: all 0.3s; }
    .modal-phone__item a {
      color: #393c41; }
    .modal-phone__item:hover {
      color: #3a3a3a;
      background-color: #d4d4d4; }
  .modal-phone__icon {
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
    transition: fill 0.3s; }

.modalMenu {
  width: 310px;
  position: fixed;
  height: 100%;
  z-index: 10000;
  transform: translateX(100%);
  right: 0;
  top: 0;
  overflow: auto;
  font-family: 'Gotham', sans-serif;
  padding: 100px 32px 48px 20px;
  transition: all .3s ease-in-out;
  background-color: #ffffff; }
  @media screen and (max-width: 1000px) {
    .modalMenu {
      padding-top: 48px; } }
  @media screen and (max-width: 360px) {
    .modalMenu {
      width: 100%; } }
  .modalMenu.open {
    transform: translateX(0); }
  .modalMenu__close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer; }
    .modalMenu__close-icon {
      width: 30px;
      height: 30px; }
      @media screen and (max-width: 414px) {
        .modalMenu__close-icon {
          width: 24px;
          height: 24px; } }
  .modalMenu__item:not(:last-child) {
    margin-bottom: 10px; }
  .modalMenu__item.hidden {
    display: none; }
    @media screen and (max-width: 1000px) {
      .modalMenu__item.hidden {
        display: block; } }
  .modalMenu__link {
    display: block;
    font-size: 14px;
    color: #000000;
    transition: all .3s;
    padding: 12px 8px 12px 24px; }
    .modalMenu__link:hover {
      border-radius: 4px;
      backdrop-filter: blur(16px);
      background-color: rgba(0, 0, 0, 0.05); }
