@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
  font-size: $base-font-size;
  height: 100%;
}

body {
  height: 100%;
  font-family: $base-fonts;
  padding: 0;
  margin: 0;
  &.hidden {
    overflow: hidden;
  }
  @include media(1100px) {
    overflow: unset;
  }
}

.pg-wrapper.pg-vertical {
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 { margin: 0; }

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a { text-decoration: none; }

p { margin: 0; }

img {
  max-width: 100%;
  display: block;
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea { vertical-align: middle; }

textarea { resize: none; }

section { position: relative; }

input,
select,
button { outline: none; cursor: pointer; border: none; background-color: transparent; }

*, *:before, *:after { box-sizing: border-box; }

.content { flex: 1 0 auto; }

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0,0,0, 0.3);
  left: 50%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: background-color 0.5s ease, backdrop-filter 0.5s ease;
  z-index: 999;
  &.open {
    backdrop-filter: blur(4px);
    pointer-events: inherit;
    opacity: 1;
    visibility: visible;
  }
}

//Модули
@import "modules/modules";
