.header {
  $this: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 32px;
  &#{$this}--hide {
    display: none;
  }
  &#{$this}--fixed {
    position: fixed;
    z-index: 1000;
    width: 100%;
    left: 0;
    top: 0;
  }
  &#{$this}--white {
    .headerNav {
      &__link,
      &__item {
        color: $white;
      }
    }
    .modal-phone {
      &__wrapper {
        &:not(:last-child) {
          border-bottom-color: $white;
        }
      }
      &__item {
        color: $white;
        &:hover {
          background-color: #886d58;
        }
      }
      &__icon {
        fill: $white;
      }
    }
  }
  &#{$this}--blur {
    backdrop-filter: blur(16px);
  }
  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @include media(700px) {
    padding: 0 24px;
  }
  @include media(414px) {
    padding: 0 15px;
  }
}

.logo {
  display: block;
  max-width: 120px;
}

.sandwich {
  display: none;
  @include media(1100px) {
    display: block;
  }
  &__icon {
    width: 22px;
    height: 18px;
  }
}

.headerNav {
  $this: &;

  font-family: 'Gotham', sans-serif;
  &__list {
    display: flex;
  }
  &__phone {
    position: relative;
    &:hover {
      .modal-phone {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &__item {
    font-size: 14px;
    cursor: pointer;
    color: $black;
    padding: 4px 16px;
    height: 32px;
    line-height: 24px;
    transition: all .35s ease-in-out;
    @include media(500px) {
      border-radius: 4px;
      backdrop-filter: blur(16px);
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  &__link {
    color: $black;
  }
  &__backdrop {
    position: absolute;
    z-index: -1;
    backdrop-filter: blur(16px);
    background-color: rgba(0,0,0, 0.05);
    border-radius: 4px;
    transform: translateX(-60px);
    transition: all .35s ease-in-out;
  }
  #{$this}__phone {
    @include media(500px) {
      display: none;
    }
  }
  &.hidden {
    display: block;
    @include media(1000px) {
      display: none;
    }
  }
}


.homePages {
  $this: &;

  height: 100vh;
  &__inner {
    position: absolute;
    @include size(100%);
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 17vh 24px 7vh;
    font-family: 'Copyright', sans-serif;
    @include media(461px) {
      padding: 15vh 15px 7vh;
      // height: 70%;
    }
  }
  &__socials {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    & > *:not(:last-child) {
      margin-right: 20px;
    }
    @include media(430px) {
      margin-bottom: 20px;
    }
    a {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      color: $tds-color--black;
      & > *:not(:last-child) {
        margin-right: 10px;
      }
      img { 
        @include size(40px);
        @include media(375px) {
          @include size(30px);
        }
      }
    }
  }
  &__location {
    display: flex;
    flex-direction: column;
    padding: 24px 30px;
    height: 100vh;
    color: $tds-color--black;
    @include media(375px) {
      padding: 24px 15px;
    }
    &-after {
      background: linear-gradient(90.04deg, #000000 9.52%, rgba(0, 0, 0, 0.37) 28.05%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 0.05;
      font-weight: 600;
      font-size: 50px;
      line-height: 85px;
      @include media(414px) {
        font-size: 40px;
        line-height: 55px;
      }
    }
    &-title {
      font-weight: 600;
      font-size: 30px;
      margin-bottom: 36px;
      padding-left: 25px;
    }
  }
  &__links {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transition: all .8s ease-in-out;
    // transform: translateY(200px);
  }
  &__link {
    border-radius: 4px;
    height: 40px;
    text-align: center;
    flex: 1;
    padding: 0 24px;
    font-size: 14px;
    font-family: 'Gotham', sans-serif;
    line-height: 40px;
    &:not(:last-child) {
      margin-right: 32px;
    }
    @include media(461px) {
      flex: 0 0 100%;
      &:not(:last-child) {
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
    &:first-child {
      background-color: rgba(23, 26, 32, 0.8);
      color: $white;
    }
    &:last-child {
      color: $tds-color--grey20;
      background-color: rgba(244, 244, 244, 0.65);
      backdrop-filter: blur(8px);
    }
  }
  &__item {
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:nth-child(7),
    &:nth-child(8) {
      height: auto;
    }
    &:nth-child(6) {
      #{$this}__title {
        color: $white;
      }
    }
    &.active {
      #{$this}__title {
        opacity: 1;
        transform: scale(1);
      }
      #{$this}__links {
        transform: translateY(0);
      }
    }
    &#{$this}--location {
      display: none;
      @include media(414px) {
        display: block;
      }
    }
  }
  &__bottom {
    margin: auto 0 0;
    @include media(376px) {
      margin-top: 0;
    }
  }
  &__title {
    font-size: 40px;
    // opacity: 0;
    font-family: 'GothamPro', sans-serif;
    // transform: scale(0);
    font-weight: 500;
    transition: all 0.8s;
    @include media(414px) {
      font-size: 34px;
    }
  }
  &__img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    img {
      @include media(1281px) {
        width: 120%;
        max-width: 120%;
        margin-left: -10%;
      }
      @include media(1025px) {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }
    }
  }
}

.homePageTabs {
  @include media(376px) {
    margin-bottom: 30%;
  }
  &__top {
    position: relative;
    z-index: 2;
    display: flex;
    border-bottom: 2px solid rgba(155, 155, 155, 0.3);
    margin-bottom: 25px;
  }
  &__item {
    position: relative;
    z-index: 1;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
    line-height: 25px;
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
    &.active {
      display: flex;
    }
  }
  &__map {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: -1;
    @include media(414px) {
      top: -30px;
    }
    @include media(375px) {
      right: -15px;
    }
  }
  &__btn {
    font-weight: 500;
    font-size: 15px;
    border-radius: 4px;
    background-color: $tds-color--black;
    color: $white;
    padding: 10px 15px;
  }
  &__phone {
    color: $tds-color--black;
  }
  &__address {
    max-width: 210px;
  }
  &__tab {
    position: relative;
    font-weight: 600;
    font-size: 20px;
    flex: 1;
    padding-bottom: 5px;
    color: rgba(37, 37, 37, 0.3);
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 2px;
      background-color: transparent;
    }
    &.active {
      color: $tds-color--black;
      &:after {
        background-color: $tds-color--black;
      }
    }
  }
}



.copyright {
  position: relative;
  z-index: 1;
  font-size: 12px;
  text-align: center;
  color: rgba(37, 37, 37, 0.6);
  a {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    color: rgba(37, 37, 37, 0.6);
  }
}

#roadster {
  &.pg-active {
    .homePages__title {
      color: $white;
    }
  }
}