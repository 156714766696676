.detailsCar {
  $this: &;

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    margin-bottom: 50px;
    @include media(1367px) {
      font-size: 36px;
      line-height: 42px;
    }
    @include media(1200px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &.modelPrices {
    #{$this}__slider {
      @include media(1100px) {
        margin-bottom: 50px;
      }
    }
    #{$this}__title {
      text-align: left;
      font-weight: bold;
      color: #2B2B2B;
      @include media(1100px) {
        text-align: center;
        margin-bottom: 30px;
        padding: 0 15px;
      }
    }
    .openGallery {
      @include media(1100px) {
        width: 80%;
        max-width: 550px;
        position: relative;
        text-align: center;
        justify-content: center;
        border-radius: 0 0 20px 20px;
        padding: 12px;
      }
      @include media(700px) {
        max-width: 300px;
      }
      @include media(414px) {
        font-size: 14px;
        gap: 10px;
      }
      &__btn {
        @include media(1100px) {
          @include size(30px);
        }
      }
      &__icon {
        @include media(1100px) {
          @include size(18px);
          opacity: 1;
          transform: rotate(180deg);
        }
      }
    }
  }
  &__mod {
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    color: rgba(0,0,0, 0.8);
    text-align: center;
    margin-bottom: 30px;
    @include media(1200px) {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
  &__prices {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    padding: 30px;
    background-color: $tds-color--grey70;
    margin-bottom: 37px;
    @include media(1200px) {
      padding: 20px;
    }
  }
  &__info {
    padding-right: 70px;
    overflow: hidden auto;
    margin-right: -20px;
    padding-bottom: 20px;
    #{$this}__title {
      @include media(1100px) {
        display: none;;
      }
    }
    @include media(1367px) {
      padding-right: 35px;
    }
    @include media(1200px) {
      padding-right: 30px;
    }
    @include media(1100px) {
      padding-right: 34px;
      padding-left: 34px;
      margin-right: 0;
      padding-bottom: 80px;
    }
    @include media(414px) {
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 50px;
    }
  }
  &__feature {
    display: flex;
    gap: 27px;
    margin-bottom: 35px;
    justify-content: space-between;
    @include media(1200px) {
      margin-bottom: 20px;
      gap: 10px;
    }
    @include media(1100px) {
      max-width: 600px;
      margin: 0 auto 20px;
    }
    li {
      text-align: center;
      font-size: 12px;
      color:rgba(0,0,0, 0.5);
      @include media(1200px) {
        font-size: 10px;
      }
      span {
        display: block;
        color: #393c41;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 5px;
        @include media(1200px) {
          font-size: 18px;
        }
      }
    }
  }
  &__inner {
    display: grid;
    grid-template-columns: 1fr 550px;
    gap: 30px;
    overflow: hidden;
    height: calc(100vh - 60px);
    @include media(1367px) {
      grid-template-columns: 1fr 400px;
    }
    @include media(1200px) {
      grid-template-columns: 1fr 320px;
    }
    @include media(1100px) {
      grid-template-columns: 1fr;
      height: auto;
    }
  }
  &__slider {
    position: sticky;
    top: 0;
    overflow: hidden;
    @include media(1100px) {
      position: static;
    }
  }
}

.modelPrices {
  $this: &;

  &__list {
    margin-bottom: 75px;
    @include media(1367px) {
      margin-bottom: 45px;
    }
    @include media(1100px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__inner {
    display: flex;
    margin-bottom: 60px;
    @include media(1100px) {
      justify-content: center;
    }
    @include media(414px) {
      width: calc(100% + 30px);
      margin: 0 -15px 40px;
    }
  }
  &__btn {
    background: #2B2B2B;
    border-radius: 5px;
    color:rgba(255, 255, 255, 0.8);
    height: 48px;
    padding: 0 12px;
    font-size: 18px;
    font-family: $base-fonts;
    transition: color .3s;
    &:hover {
      color: $white;
      @include media(1100px) {
        background-color: #2B2B2B;
      }
    }
    @include media(1100px) {
      background-color: $white;
      color: #2B2B2B;
      border: 2px solid #2B2B2B;
      font-weight: 500;
    }
    @include media(414px) {
      font-size: 16px;
      height: 44px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    color: #2B2B2B;
    flex: 1;
    font-weight: 500;
    &:first-child {
      #{$this}__col {
        padding-left: 20px;
        @include media(414px) {
          padding-left: 10px;
        }
        &:first-child {
          border-top-left-radius: 5px;
          @include media(414px) {
            border-top-left-radius: 0;
          }
        }
        &:last-child {
          border-bottom-left-radius: 5px;
          @include media(414px) {
            border-bottom-left-radius: 0;
          }
        }
      }
    }
    &:last-child {
      #{$this}__col {
        padding-right: 20px;
        &:first-child {
          border-top-right-radius: 5px;
          @include media(414px) {
            border-top-right-radius: 0;
          }
        }
        &:last-child {
          
          @include media(414px) {
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
  &__col {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    white-space: nowrap;
    background: #DDDDDD;
    flex: 1;
    &:nth-child(odd) {
      background: #EFEFEF;
    }
    @include media(1367px) {
      font-size: 14px;
      padding: 10px;
    }
    @include media(375px) {
      font-size: 12px;
    }
  }
}

.swiperDetails {
  $this: &;

  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  @include media(1100px) {
    position: static;
  }
  &:hover {
    #{$this}__arrows {
      width: 100%;
      opacity: 1;
      pointer-events: all;
    }
  }
  &__arrows {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    @include center;
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    @include media(1100px) {
      display: none;
    }
  }
  &__icon {
    @include size(28px);
    border-radius: 5px;
    padding: 5px;
    background-color: $tds-color--grey65;
    cursor: pointer;
  }
  &__arrow {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 40px;
  }
}

.detailsPayment {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__item {
    position: relative;
    display: flex;
    align-self: flex-start;
    gap: 20px;
    background-color: $white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 12px;
    @include media(500px) {
      margin-top: 20px;
    }
    span {
      font-size: 14px;
      @include media(500px) {
        position: absolute;
        left: 0;
        top: -20px;
      }
    }
  }
  &__rub,
  &__usd {
    font-weight: 600;
    font-size: 16px;
  }
}

.detailsPrice {
  &-cash {
    font-size: 14px;
  }
  &-rub {
    font-weight: 600;
    font-size: 40px;
    @include media(1200px) {
      font-size: 32px;
    }
    @include media(414px) {
      font-size: 26px;
    }
  }
  &-usd {
    font-weight: 600;
    font-size: 16px;
    color: rgba(0,0,0, 0.4);
  }
  &__btn {
    align-self: flex-start;
    background: #2B2B2B;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    transition: all .3s;
    cursor: pointer;
    padding: 12px;
    color: $white;
    &:hover {
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.3);
    }
  }
}

.qualitiesCar {
  margin-bottom: 37px;
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 10px;
    @include media(500px) {
      font-size: 16px;
    }
  }
  &__item {
    position: relative;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    padding-left: 15px;
    @include media(500px) {
      font-size: 12px;
      line-height: 24px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @include size(4px);
      border-radius: 100%;
      background-color: $black;
    }
  }
}

.openGallery {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  z-index: 100;
  cursor: pointer;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 40px;
  font-weight: 500;
  bottom: 0;
  background: #f4f4f4;
  border-radius: 10px;
  border-radius: 10px 10px 0 0;
  @include media(1100px) {
    position: fixed;
  }
  @include media(500px) {
    padding: 15px 25px;;
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(40px);
    flex: 0 0 auto;
    border-radius: 100%;
    background-color: $white;
    transition: all .3s;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
    @include media(500px) {
      @include size(30px);
    }
    &:hover {
      filter: drop-shadow(0 2px 4px rgba(0,0,0,.2));
    }
  }
  &__icon {
    @include size(24px);
    opacity: 0.4;
    @include media(500px) {
      @include size(20px);
    }
  }
}

.outputPrice {
  @extend .openGallery;
  gap: 5px;
  color: $tds-color--grey20;
  font-weight: 600;
  @include media(414px) {
    font-size: 14px;
    padding: 10px 15px;
  }
}

.tabPrices {
  margin-top: 50px;
  font-family: 'Gotham', sans-serif;
  font-weight: 500;
  &__inner {
    display: flex;
    height: 40px;
    border-radius: 4px;
    gap: 4px;
    padding: 4px;
    background-color: $tds-toggle--background-color;
    color: $tds-color--grey30;
    font-size: 12px;
  }
  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    flex: 1;
    transition: all .3s;
    cursor: pointer;
    border-radius: 4px;
    &.active {
      background-color: $white;
      color: $tds-color--grey10;
      box-shadow: 0px 1px 9px -6px rgba(0, 0, 0, 0.5);
    }
  }
  &__item {
    display: none;
    &.active {
      display: block;
    }
  }
  &__content {
    font-size: 14px;
    padding: 16px 0;
    color: $tds-color--grey20;
  }
  &__currency,
  &__lease,
  &__loan {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.modal-cars {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  z-index: 1000;
  &.active {
    display: block;
  }
  &__inner {
    display: flex;
    align-items: center;
  }
}

.modalCars {
  padding: 0 20px;
  &__slide {
    max-width: 600px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    &:not(.swiper-slide-active) {
      filter: brightness(.5);
    }
  }
  &__close {
    position: absolute;
    @include size(40px);
    padding: 10px;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  &__icon {
    max-width: 100%;
    height: 100%;
  }
  &__arrows {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    position: absolute;
    left: 50%;
    top: calc(50% - 30px);
    transform: translate(-50%, -50%);
    @include media(600px) {
      width: calc(100% - 40px);
    }
    @include media(414px) {
      display: none;
    }
  }
  &__dots {
    text-align: center;
    padding-top: 30px;
    @include media(600px) {
      padding-top: 15px;
    }
    @include media(414px) {
      padding-top: 10px;
    }
  }
  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(64px);
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    transition: all .3s;
    background-color: #a1a2a4;
    @include media(600px) {
      padding: 8px;
      @include size(40px);
    }
    &.swiper-button-disabled {
      opacity: 0;
    }
    svg {
      fill: $white;
      height: 100%;
    }
  }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: $white;
}

.configCar {
  .detailsCar__inner {
    grid-template-columns: 1fr 370px;
    @include media(1100px) {
      grid-template-columns: 1fr;
    }
  }
}

.prices {
  $this: &;

  &__item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  &__input {
    &:checked + label {
      box-shadow: inset 0 0 0 3px #3e6ae1;
      color: $black;
    }
  }
  &__title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &__label {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    flex-flow: row wrap;
    border-radius: 4px;
    font-weight: 500;
    color:rgba(0, 0, 0, 0.5);
    height: 48px;
    padding: 0 20px;
    cursor: pointer;
    box-shadow: inset 0 0 0 1px #a2a3a5;
    transition: all .33s cubic-bezier(.5,0,0,.75);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
  }
  &__info {
    color: #5c5e62;
    font-size: 12px;
    text-align: center;
    line-height: 17px;
    font-weight: 500;
    margin-top: 5px;
  }
}

.openSlider {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  opacity: 0;
  pointer-events: none;
  @include media(414px) {
    margin: 20px 0;
  }
  &__btn {
    display: flex;
    align-items: center;
    text-align: center;
    height: 40px;
    font-weight: 600;
    border-radius: 4px;
    background-color: #f4f4f4;
    padding: 0 20px;
    cursor: pointer;
    font-size: 14px;
    transition: all .3s;
    &:hover {
      background-color: #eee;
    }
  }
}

.filterCar {
  $this: &;

  &__look {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 150px;
      @include media(769px) {
        margin-bottom: 100px;
      }
    }
    &.paint {
      #{$this}__mobile {
        img {
          @include media(769px) {
            max-width: 140%;
            margin: -20%;
          }
        }
      }
    }
    &.Interior {
      @include media(414px) {
        margin-bottom: 70px;
      }
      #{$this}__mobile {
        @include media(769px) {
          margin-bottom: 40px;
        }
        @include media(414px) {
          margin-bottom: 20px;
        }
      }
    }
    &.wheels {
      #{$this}__item {
        &:nth-child(3) {
          display: none;
        }
      } 
    }
  }
  &__mobile {
    display: none;
    overflow: hidden;
    margin: 0 -34px;
    @include media(769px) {
      display: block;
    }
    @include media(414px) {
      margin: 0 -15px;
    }
  }
  &__title {
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
    font-weight: 500;
    font-family: 'Gotham', sans-serif;
    @include media(414px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  &__inner {
    display: flex;
    justify-content: center;
  }
  &__item {
    @include size(54px);
    padding: 4px;
    margin: 0 4px;
  }
  &__label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    &:after {
      content: '';
      @include center;
      transition: opacity .3s;
      @include size(58px);
      border-radius: 100%;
      opacity: 0;
      box-shadow: inset 0 0 0 3px #3e6ae1;
    }
  }
  &__input {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    &:checked + label {
      &:after {
        opacity: 1;
      }
    }
  }
  &__value {
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Gotham', sans-serif;
    margin-top: 16px;
    padding: 0 20px;
  }
}

.orderCar {
  margin-top: 50px;
  &__title {
    @extend .filterCar__title;
  }
  &__date {
    text-align: center;
    font-family: 'Gotham', sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 24px;
  }
  a {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.featureCar {
  margin-top: 100px;
  &__title {
    @extend .filterCar__title;
  }
  &__list {
    padding-left: 24px;
    margin-left: 5px;
  }
  &__item {
    list-style: disc;
    font-size: 14px;
    padding: 10px 5px;
    span {
      padding: 0 5px;
    }
  }
}

.modelOption {
  margin-bottom: 50px;
  @include media(414px) {
    margin: 0 -15px 30px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #1E1E1E;
    padding: 15px 20px;
    font-size: 18px;
    @include media(1367px) {
      font-size: 14px;
      padding: 10px 15px;
    }
    &:last-child {
      border-bottom: 1px solid #1E1E1E;
    }
  }
  &__name {
    font-weight: 500;
  }
  &__value {
    flex-basis: 30%;
  }
}

.modelDetail {
  $this: &;

  margin-bottom: 250px;
  @include media(1100px) {
    margin-bottom: 100px;
  }
  &[data-model="corps"] {
    #{$this}__caption {
      overflow: hidden;
      &:first-child {
        img {
          max-width: 140%;
          margin: -20%;
        }
      }
    }
  }
  &__caption {
    display: none;
    margin: 0 -34px 50px;
    @include media(1100px) {
      display: block;
    }
    @include media(414px) {
      margin: 0 -15px 30px;
    }
  }
  &__title {
    font-size: 45px;
    font-weight: bold;
    color: #2B2B2B;
    margin-bottom: 50px;
    @include media(1367px) {
      font-size: 30px;
      margin-bottom: 20px;
    }
    @include media(1100px) {
      text-align: center;
    }
    @include media(414px) {
      font-size: 24px;
    }
  }
  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    @include size(150px);
    border-radius: 100%;
    border: 1px solid #333333;
    padding: 16px;
    @include media(1367px) {
      padding: 10px;
      @include size(100px);
    }
    @include media(414px) {
      padding: 5px;
      @include size(60px);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__type {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;
    font-size: 28px;
    @include media(1100px) {
      margin: 0 auto;
      font-size: 20px;
      gap: 10px;
    }
  }
}

.modelMobile {
  display: none;
  @include media(1100px) {
    display: block;
  }
  &__caption {
    img {
      position: relative;
      z-index: -1;
      max-width: 140%;
      margin: -22% -20% -10%;
      @include media(414px) {
        max-width: 180%;
        margin: -35% -40% -10%;
      }
    }
  }
}

.modelForm {
  $this: &;

  max-width: 405px;
  margin-bottom: 50px;
  .socials {
    margin-top: 50px;
    @include media(1100px) {
      justify-content: center;
      gap: 50px;
    }
  }
  @include media(1100px) {
    margin: 0 auto;
  }
  &__title {
    font-weight: 700;
    font-size: 45px;
    line-height: 120%;
    margin-bottom: 20px;
    @include media(1367px) {
      text-align: center;
      font-size: 36px;
    }
    @include media(414px) {
      font-size: 28px;
      margin-bottom: 5px;
    }
    @include media(375px) {
      font-size: 24px;
    }
  }
  &__text {
    font-size: 20px;
    line-height: 120%;
    @include media(1100px) {
      text-align: center;
    }
    @include media(414px) {
      font-size: 18px;
    }
    @include media(375px) {
      font-size: 16px;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    gap: 10px;
    @include media(1367px) {
      margin-top: 35px;
    }
    @include media(414px) {
      max-width: 275px;
      margin: 35px auto 0;
    }
  }
  &__check {
    order: 1;
    padding-left: 40px;
    @include media(1100px) {
      font-size: 14px;
    }
    @include media(414px) {
      font-size: 12px;
    }
    &-icon {
      position: absolute;
      left: -36px;
      top: 4px;
      transform: scale(0);
      transition: all .3s;
      @include size(12px);
    }
    input {
      position: absolute;
      @include size(1px);
      visibility: hidden;
      opacity: 0;
      & + label {
        position: relative;
        cursor: pointer;
        &:before {
          content: '';
          position: absolute;
          left: -40px;
          top: 0;
          @include size(20px);
          background: #EFEFEF;
          border-radius: 4px;
        }
      }
      &:checked {
        & + label {
          #{$this}__check-icon {
            transform: scale(1);
          }
        }
      }
    }
  }
  &__btn {
    font-weight: 500;
    font-size: 20px;
    height: 48px;
    border-radius: 5px;
    padding: 0 48px;
    margin: 50px 0;
    color: $black;
    align-self: flex-start;
    border: 2px solid $black;
    transition: all .3s;
    @include media(1100px) {
      width: 100%;
      margin: 30px 0 20px;
    }
    @include media(414px) {
      height: 44px;
      font-size: 16px;
    }
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
  &__input {
    width: 100%;
    background: #EFEFEF;
    border-radius: 5px;
    height: 70px;
    font-size: 20px;
    font-family: $base-fonts;
    color: $black;
    font-weight: 200;
    padding: 0 20px;
    @include media(1100px) {
      font-size: 16px;
      height: 48px;
    }
    &::placeholder {
      color: $black;
      transition: all .3s
    }
    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }
  }
}

.socials {
  $this: &;

  display: flex;
  align-items: center;
  gap: 20px;
  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 15px;
    color: #252525;
    transition: color .3s;
    &.whatsapp {
      &:hover {
        color: #01bf65;
        #{$this}__icon {
          fill: #01bf65;
        }
      }
    }
    &.telegram {
      &:hover {
        color: #15b4f0;
        #{$this}__icon {
          fill: #15b4f0;
        }
      }
    }
  }
  &__icon {
    @include size(34px);
    fill: #252525;
    transition: fill .3s;
  }
}

.thumbSwiper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  @include media(1100px) {
    height: auto;
    flex-direction: column;
    align-items: center;
  }
  &__inner {
    display: flex;
    position: absolute;
    gap: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    bottom: 45px;
    padding: 35px 20px 50px;
    background: #F4F4F4;
    @include media(1367px) {
      padding: 20px 20px 40px;
    }
    @include media(1100px) {
      position: static;
      transform: translate(0, 0);
      padding: 20px;
    }
    @include media(700px) {
      gap: 7px;
      padding: 20px 15px;
    }
    @include media(360px) {
      width: 100%;
      gap: 5px;
      justify-content: center;
    }
  }
  
  &__item {
    cursor: pointer;
    @include size(110px);
    border-radius: 6px;
    overflow: hidden;
    border: 2px solid transparent;
    transition: all .3s;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
    @include media(1367px) {
      @include size(80px);
    }
    @include media(700px) {
      @include size(60px);
    }
    @include media(360px) {
      @include size(50px);
    }
    &.active {
      border-color: #d9d9d9;
    }
  }
}

.swiperConfig {
  @extend .swiperDetails;
  .swiperDetails__slide {
    @include media(769px) {
      margin-right: 20%;
    }
    img {
      @include media-min(1100px) {
        max-width: 120%;
        margin-left: -10%;
      }
      @include media(769px) {
        max-width: 140%;
        margin: -20%;
      }
    }
  }
}

.swiperModel {
  $this: &;

  @extend .swiperDetails;
  @include media(1100px) {
    margin-bottom: 50px;
  }
  @include media(414px) {
    margin-bottom: 30px;
  }
  &__dots.swiper-pagination-horizontal {
    bottom: 270px;
    @include media(1367px) {
      bottom: 220px;
    }
  }
  &__slide {
    @include media(1100px) {
      overflow: hidden;
    }
    img {
      @include media(1367px) {
        max-width: 140%;
        margin: -10% -20%;
      }
    }
  }
  &__dots {
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 10px;
    z-index: 20;
    @include media(1100px) {
      display: none;
    }
  }
  &__dot {
    width: 22px;
    height: 6px;
    border-radius: 6px;
    background-color: #6C6C6C;
    cursor: pointer;
    transition: all .3s;
    &#{$this}__dot-active {
      background-color: #252525;
    }
  }
}

a.sunNews__recommend img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  object-fit: cover;
  z-index: -1;
}