@mixin size($a, $border: false) {
  width: $a;
  height: $a;
  @if $border {
    border-radius: $border;
  }
}

@mixin center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%)
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

@mixin links ($link, $visited, $hover, $active) {
  & {
    color: $link;
    &:visited {
        color: $visited;
    }
    &:hover {
        color: $hover;
    }
    &:active, &:focus {
        color: $active;
    }
  }
}

@mixin media($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}

@mixin media-min($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin media-max-min($max, $min) {
  @media only screen and (max-width: $max) and (min-width: $min)  {
    @content;
  }
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin line-clamp($count) {
  display: -webkit-box;
  -webkit-line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

